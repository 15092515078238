<template>
  <div class="login-form login-signin">
    <form
      class="form"
      novalidate="novalidate"
      id="kt_login_signup_form"
      @submit.stop.prevent="onSubmit"
    >
      <div class="pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Cadastre-se
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          Insira os detalhes da sua conta
        </p>
      </div>
      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          placeholder="Nome Completo"
          v-model="form.name"
          name="name"
          ref="name"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="text"
          placeholder="Celular"
          name="phone"
          v-model="form.phone"
          ref="phone"
          autocomplete="off"
          v-mask="['(##) ####-####', '(##) #####-####']"
        />
      </div>
      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="email"
          placeholder="Email"
          name="email"
          v-model="form.email"
          ref="email"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="password"
          placeholder="Password"
          name="password"
          v-model="form.password"
          ref="password"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <input
          class="
            form-control form-control-solid
            h-auto
            py-7
            px-6
            rounded-lg
            font-size-h6
          "
          type="password"
          placeholder="Confirmar senha"
          v-model="form.password_confirmation"
          ref="password_confirmation"
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <Terms @accept="accepted" @accepted="accepted" />
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          ref="kt_login_signup_submit"
          class="
            btn btn-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
            mr-4
          "
          style="width: 150px"
        >
          CADASTRAR
        </button>
        <button
          type="button"
          id="kt_login_signup_cancel"
          class="
            btn btn-light-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
          "
          @click="$router.push('/login')"
        >
          CANCELAR
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { LOGOUT } from '@/core/services/store/auth.module'
import { REGISTER, GET_INVITE } from '@/core/services/store/auth.module'
import { mask } from 'vue-the-mask'
import Terms from '@/components/Auth/Terms'

export default {
  name: 'RegisterSalesman',

  components: { Terms },

  directives: { mask },

  data: () => ({
    loadingTerms: false,
    terms: null,
    loading: null,
    form: {
      name: null,
      phone: null,
      email: null,
      token: null,
      password: null,
      store: null,
      type: 'salesman',
      password_confirmation: null,
      agree: null
    }
  }),

  methods: {
    resetForm() {
      this.form = {
        name: null,
        phone: null,
        email: null,
        token: null,
        store: null,
        password: null,
        type: 'salesman',
        password_confirmation: null,
        agree: null
      }

      this.$nextTick(() => {
        this.$v.$reset()
      })
    },

    accepted(agree) {
      this.form.agree = agree
    },

    onSubmit() {
      this.$store.dispatch(LOGOUT)

      const submitButton = this.$refs['kt_login_signup_submit']
      submitButton.classList.add('spinner', 'spinner-light', 'spinner-right')

      this.$store.dispatch(REGISTER, this.form).then(() => {
        this.$router.push('/dashboard')
      })

      submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right')
    }
  },

  created() {
    let url = window.location.href
    if (url.includes('steps=1')) {
      this.steps = true
    }
  },

  mounted() {
    const token = window.location.hash.replace('#/convite?token=', '')
    this.$store
      .dispatch(GET_INVITE, token)
      .then(({ name, email, token, phone, store_id }) => {
        if (name) {
          this.form.name = name
          this.form.email = email
          this.form.phone = phone
          this.form.token = token
          this.form.store = store_id
        } else {
          this.$alert('Este convite não é mais válido.')
          this.$router.push('/login')
        }
      })
  }
}
</script>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>
